<template>
  <div class="projectsWrap">
    <div class="searchWrap">
      <span class="label"> 工程名称： </span>
      <el-input
        v-model="searchValue"
        clearable
        placeholder="请输入工程名称"
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <div class="toolbarOperations">
      <el-button
        size="mini"
        v-for="t in toolbarOperations"
        :key="t.key"
        :type="t.key == 'delete' ? 'danger' : 'primary'"
        @click="operationHandler(t.key)"
        >{{ t.text }}</el-button
      >
    </div>

    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="projectsTable"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ dateTransform(scope.row[c.prop]) }}
          </div>
          <div v-else-if="c.prop == 'project_img'">
            <img
              class="projectImg"
              v-if="scope.row[c.prop]"
              :src="scope.row[c.prop]"
            />
          </div>
          <div v-else-if="c.prop == 'type'">
            {{ getLevelName(scope.row.type) }}
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.row)"
            >
              编辑
            </el-button>

            <el-button
              size="mini"
              type="primary"
              @click="handleUploadImage(scope.row.id)"
            >
              上传图片
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="showShareDialog(scope.row)"
            >
              分享
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :loading="exportLoad && editableRows.includes(scope.$index)"
              :disabled = "scope.row.package_path === '' ||scope.row.package_path === null  ? false : true"
              @click="exportProjects(scope.row,scope.$index)"
            >
              导出
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled = "scope.row.package_path === '' ||scope.row.package_path === null || scope.row.package_path === 'done' ? true : false"
              @click="downloadProjects(scope.row)"
            >
              下载
            </el-button>
          </div>
          <span style="margin-left: 10px" v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="tableDataInfo.total > 8"
      :handleSizeChange="
        (v) => {
          this.getTableData({ page_size: v });
        }
      "
      :handleCurrentPageChange="
        (v) => {
          this.getTableData({ page_num: v });
        }
      "
      :total="tableDataInfo.total"
    />

    <!-- 图片资源的modal -->
    <ImagesResourceModal
      ref="imagesResourceModal"
      modalOperationKey="other"
      :confirm="changeProjectImage"
    />
    <el-dialog
      :title="chooseDialogTitle"
      :visible="dialogVisible"
      width="360px"
      :center="dialogType == 'share'"
      custom-class="newProject"
      :before-close="
        () => {
          this.dialogVisible = false;
        }
      "
    >
      <div v-if="dialogType == 'createProject'">
        <div class="level" v-for="l in projectLevels" :key="l.id">
          <span>{{ l.project_type }}工程</span>
          <span @click="toCreateProject(l)" class="create">创建</span>
        </div>
      </div>
      <div v-else>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="shareProjectReason"
        />
      </div>
      <span slot="footer" class="shareFooter" v-if="dialogType == 'share'">
        <el-button type="primary" @click="shareProject">分享</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import ImagesResourceModal from "@/components/ImagesResourceModal";
import Pagination from "@/components/Pagination";
import {downloadFileByBlob} from '@/utils/utils';
export default {
  data() {
    return {
      editableRows: [],
      exportLoad:false,
      tableColumns: [
        {
          label: "工程名称",
          prop: "project_name",
        },
        {
          label: "工程图片",
          prop: "project_img",
          width: 200
        },
        {
          label: "画布宽度",
          prop: "project_width",
          width: 80
        },
        {
          label: "画布高度",
          prop: "project_height",
          width: 80
        },
        {
          label: "工程属性",
          prop: "type",
          width: 120
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: 130
        },
        {
          label: "修改时间",
          prop: "update_time",
          width: 130
        },
        {
          label: "操作",
          prop: "operations",
          width: "400",
        },
      ],
      tableDataInfo: {},
      searchValue: "",
      dialogVisible: false,
      toolbarOperations: [
        {
          text: "新建工程",
          key: "new",
        },
        {
          text: "工程复制",
          key: "copy",
        },
        {
          text: "工程删除",
          key: "delete",
        },
        // {
        //   text: "导出",
        //   key: "export",
        // },
      ],
      activeProjectId: "",
      dialogType: "",
      shareProjectReason: "",
      selectedProjects: [],
    };
  },
  components: {
    ImagesResourceModal,
    Pagination,
  },
  mounted() {
    this.getTableData({});
    this.$store.dispatch("getProjectLevelsAction", {});
  },
  computed: {
    ...mapState(["loginAccountInfo", "projectLevels"]),
    chooseDialogTitle() {
      return this.dialogType == "share" ? "分享" : "新建工程";
    },
  },
  methods: {
    getLevelName(l) {
      const level =
        this.projectLevels.find((item) => item.project_code == l) || {};
      return level.project_type || "未知属性";
    },
    operationHandler(key) {
      if (key == "new") {
        this.dialogType = "createProject";
        this.dialogVisible = true;
      } else if (key == "delete") {
        this.deleteProjects();
      } else if (key === 'copy') {
        this.copyProjects();
      // } else if (key === 'export') {
      //   this.exportProjects();
      }
    },
    async exportProjects(row,rowIndex) {
      this.exportLoad = true
      this.editableRows.push(rowIndex);
      // if (_.isEmpty(this.selectedProjects)) {
      //   this.$message.error("请选择工程");
      //   return;
      // }
      // 工程导出
      this.$changeLoadingState(true);
      await this.$request
        .exportByProjectId({
          id: row.id
        })
        .then((res) => {
            // 编辑操作完成后停止loading状态
        this.loading = false;
        // 移除当前行的索引
        const index = this.editableRows.indexOf(rowIndex);
        if (index !== -1) {
          this.editableRows.splice(index, 1);
        }
        // if (res.status != "success") { 
        //    this.$message.error(res.msg);
        //    return;
        //  }
        this.exportLoad = false
        setTimeout((() => {
          this.getTableData({
            project_name: this.searchValue,
          });
          this.$changeLoadingState(false);
          this.$message.success("正在后台打包中......工程打包时长与工程体积大小有关！请您耐心等待!");
          }), 5000)
         }).catch(() => {
          this.$message.error(res.msg);
          this.$message.error('打包失败！请联系网站开发者！');
          this.$changeLoadingState(false);
        });
    },
    downloadProjects(row) {
      const path = row.package_path;
          if (!path) {
            this.$message.error('此工程未行打包或者打包未完成！请执行打包或等待！');
            this.$changeLoadingState(false);
            return;
      }
      window.open(path);
          // downloadFileByBlob('/api/cloud/project/download', JSON.stringify({
          //     path: path
          // }), () => {
          //   this.$changeLoadingState(false);
          // });
    },
    copyProjects() {
      if (_.isEmpty(this.selectedProjects)) {
        this.$message.error("请选择工程");
        return;
      }
      this.$request
        .copyProjects({ ids: this.selectedProjects.map((item) => item.id) })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.getTableData({});
        });
    },
    deleteProjects() {
      if (_.isEmpty(this.selectedProjects)) {
        this.$message.error("请选择工程");
        return;
      }
      this.$confirm('确认要删除勾选的工程吗？')
        .then(_ => {
          this.$request
          .deleteProjects({ ids: this.selectedProjects.map((item) => item.id) })
          .then((res) => {
            if (res.status != "success") {
              this.$message.error(res.msg);
              return;
            }
            this.getTableData({});
          });
        });
    },
    changeProjectImage(src) {
      this.$request
        .updateProjectImg({
          project_img: src,
          id: this.activeProjectId,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$refs.imagesResourceModal.switchDialogVisible(false);
          this.getTableData({});
        });
    },
    handleSelectionChange(val) {
      this.selectedProjects = val;
    },
    toCreateProject(l) {
      const { pages, project_code, btns, ip_ports } = l;
      const limits = btoa(
        JSON.stringify({ project_code, pages, btns, ip_ports })
      );
      this.$router.push({
        path: "/projects/add",
        query: {
          limits,
        },
      });
    },
    getProjectLevelsData() {
      this.$request.getProjectLevelsList({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }

        this.projectLevels = res?.data?.result || [];
      });
    },
    getTableData({ project_name, page_num = 1, page_size = 8 }) {
      this.$request
        .getProjectsList({ page_num, page_size, project_name })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.tableDataInfo = res.data || {};
        });
    },
    handleEdit(row) {
      const { pages, project_code, btns, ip_ports } =
        this.projectLevels.filter((item) => item.project_code == row.type)[0] ||
        {};

      const limits = btoa(
        JSON.stringify({ project_code, pages, btns, ip_ports })
      );

      this.$router.push({
        path: "/projects/projectsEdit",
        query: {
          limits,
          id: row.id,
        },
      });
    },
    handleUploadImage(id) {
      this.$refs.imagesResourceModal.switchDialogVisible(true);
      this.activeProjectId = id;
    },
    shareProject() {
      this.$request
        .shareProject({
          project_id: this.activeProjectId,
          remark: this.shareProjectReason,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("分享成功");
          this.dialogVisible = false;
          this.shareProjectReason = "";
        });
    },
    showShareDialog(row) {
      if (!row.project_img) {
        this.$message.error('工程图片为空，不可分享');
        return;
      }
      this.dialogType = "share";
      this.dialogVisible = true;
      this.activeProjectId = row.id;
    },
    search() {
      this.getTableData({
        project_name: this.searchValue,
      });
    },
    dateTransform(d) {
      if (!d) return;
      return moment(d).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang='scss'>
.projectsWrap {
  padding: 0 24px 24px;
  background-color: #fff;
  .searchWrap {
    display: flex;
    padding: 16px 0;
    align-items: center;

    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .toolbarOperations {
    padding-bottom: 16px;
    margin-bottom: 16px;
    .el-button {
      padding: 10px 12px;
    }
  }
  .projectsTable {
    .normal {
      color: #67c23a;
    }
    .operations {
      display: flex;
      justify-content: space-evenly;
    }
    .projectImg {
      width: 100px;
      height: 60px;
    }
    .forbid {
      color: #f56c6c;
    }
  }
}
.newProject {
  .el-dialog__body {
    padding: 12px !important;
  }
  .level {
    padding: 6px 60px;

    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .create {
      color: #409eff;
      cursor: pointer;
    }
  }
}
</style>